import React, {Component} from 'react';
import Slider from 'react-slick';

import Breadcrumb from "../common/breadcrumb";

class welcome extends Component {

    constructor (props) {
        super (props)
        this.props.history.push('/')
    }

    render (){


        return (
            <div>
            </div>
        )
    }
}

export default welcome