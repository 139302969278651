import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {settings} from '../../services/script'
import { getBestSeller } from "../../services";
import { addToCart, addToCartHome, addToWishlist, addToCompare } from "../../actions";
import ProductItem from '../layouts/common/product-item.jsx';
class RelatedProduct extends Component {
    render() {
        const { items, symbol, addToCart, addToCartHome, addToWishlist, addToCompare } = this.props;


        return (
            <section className="section-b-space">
                <div className="container">
               
                    <div className="row">
                        <div className="col-12 product-related">
                            <h2>related products</h2>
                        </div>
                    </div>
                    <div className="row">
                    <Slider {...settings} className="" >
                                    { items.map((product, index ) =>
                                        <div key={index} className="col-xl-2 col-md-4 col-sm-6">
                                            <ProductItem product={product} symbol={symbol}
                                                         onAddToCompareClicked={() => addToCompare(product)}
                                                         onAddToWishlistClicked={() => addToWishlist(product)}
                                                         onAddToCartClicked={(product,qty,variantCode) => addToCartHome(product, qty,variantCode)} key={index} /> 
                                        </div>)
                                    }
                                </Slider>
                                </div>
                    {/* <div className="row search-product">
                    <Slider {...Product4} className="product-4 product-m no-arrow" >
                        {items.slice(0, 6).map((product, index) =>
                            <div key={index} className="col-xl-2 col-md-4 col-sm-6">
                                <ProductItem product={product} symbol={symbol}
                                    onAddToCompareClicked={() => addToCompare(product)}
                                    onAddToWishlistClicked={() => addToWishlist(product)}
                                    onAddToCartClicked={(product, qty, variantCode) => addToCartHome(product, 1, variantCode)} key={index} />
                            </div>)
                        }
                        </Slider>
                    </div> */}
                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        items: getBestSeller(state.data.products),
        symbol: state.data.symbol
    }
}

export default connect(mapStateToProps, { addToCart, addToCartHome, addToWishlist, addToCompare })(RelatedProduct);
