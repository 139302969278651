import React from 'react';
import {Link} from 'react-router-dom'

function LogoImage(props) {

    return <Link to={`${process.env.PUBLIC_URL}/`} >
                {/* <img src={`${process.env.PUBLIC_URL}/assets/images/icon/${props.logo}`} alt="" className="img-fluid" /> */}
                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/footer-logo.png`} alt="" className="img-fluid" style={{width:"auto",maxHeight:"100px"}} />
            </Link>;
}

export default LogoImage;