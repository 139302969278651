import React, { Component } from 'react';
import {Helmet} from 'react-helmet'
// import getProducts from '../../../api/getProduct';
// import '../../common/index.scss';
import Slider from 'react-slick';
import {
    Slider4,
    svgFreeShipping,
    svgservice,
    svgoffer,
    svgpayment} from '../../../services/script'
// Import custom components
import Collection from "./collection"
import CollectionPS1 from "./collectionPS1"
import CollectionPS3 from "./collectionPS3"
import CollectionNew from "./collection-new"
import SpecialProducts from "./specialProducts";
import ProductBlock from "../common/product-block"
import BlogSection from "../common/blogsection";
import HeaderThree from "../../common/headers/header-three"
import HeaderOne from "../../common/headers/header-one"
import FooterTwo from "../../common/footers/footer-two"
import ThemeSettings from "../../common/theme-settings"
import PremiumCollection from './premiumCollection';
import SaveAndExtraCollection from './saveAndExtraCollection';
import RegularCollection from './regularCollection';

class Pets extends Component {

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color1.css` );
    }
 
    render(){
        return (
            <div >
                <Helmet>
                    <title>Animoxkart | Pet Store - Outfit your pet in style with Animoxkart!</title>
                    <meta name="keywords" content="Animoxkart | Pet Store - Outfit your pet in style with Animoxkart!" />
                    <meta name="description" content="Discover a world of comfort and style for your loveable pets at Animoxkart. Explore our exclusive collection of dog products which includes dog harness, leash and collar designed with love and precision, ensuring your pets are not only pampered but also on-trend. Elevate your pet parenting experience with our high-quality dog accessories. For your lovable pets, because they deserve the best. Shop now!" />
                </Helmet>
                <HeaderOne logoName={'layout3/logo.png'}/>
                {/* <HeaderThree logoName={'logo/14.png'} /> */}
                <section className="p-0 small-slider" id="sectionSlider">
                    <Slider className="slide-1 home-slider">
                        <div>
                            <div className="home home46" style={{ backgroundImage: "url('https://animoxkart-products.s3.ap-south-1.amazonaws.com/home/460.jpg')"}}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <div className="slider-contain">
                                                    <div>
                                                        <h4>Paw-some savings, just for you.</h4>
                                                        <h1>let's shop</h1>
                                                        <a href="shop/regular" className="btn btn-solid">shop now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="home home45" style={{ backgroundImage: "url('https://animoxkart-products.s3.ap-south-1.amazonaws.com/home/450.jpg')"}}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <div className="slider-contain">
                                                    <div>
                                                        <h4 style={{color:"white"}}>Save big on quality dog accessories.</h4>
                                                        <h1 style={{color:"white"}}>save 20%</h1>
                                                        <a href="shop/offers" className="btn btn-solid">shop now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </Slider>
                </section>

                {/*Logo Block section*/}
                {/* <LogoBlock /> */}
                <ProductBlock />
                {/*Logo Block section end*/}

                {/*Banner Section*/}
                <section className="pt-0 mt-1 banner-6 ratio2_1">
                    <div className="container">
                        <div className="row partition3"> 
                            <div className="col-md-4">
                                <a href="shop/regular">
                                    <div className="collection-banner p-left">
                                        <div className="img-part">
                                            <img src={`https://animoxkart-products.s3.ap-south-1.amazonaws.com/banner/1.png`}
                                                 className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3">
                                            {/* <div>
                                                <h2>Dogs</h2>
                                            </div> */}
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4">
                                <a href="shop/premium">
                                    <div className="collection-banner p-right text-right">
                                        <div className="img-part">
                                            <img src={`https://animoxkart-products.s3.ap-south-1.amazonaws.com/banner/2.png`}
                                                 className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3">
                                            {/* <div>
                                                <h2>Cats</h2>
                                            </div> */}
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4">
                                <a href="shop/offers">
                                    <div className="collection-banner p-left">
                                        <div className="img-part">
                                            <img src={`https://animoxkart-products.s3.ap-south-1.amazonaws.com/banner/3.png`}
                                                 className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3">
                                            {/* <div>
                                                <h2>Premium</h2>
                                            </div> */}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="row partition3 banner-top-cls">
                            <div className="col-md-4">
                                <a href="shop/combo">
                                    <div className="collection-banner p-right">
                                        <div className="img-part">
                                            <img src={`https://animoxkart-products.s3.ap-south-1.amazonaws.com/banner/4.png`}
                                                 className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3">
                                            {/* <div>
                                                <h2>Offer Products</h2>
                                            </div> */}
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4">
                                <a href="shop/regular">
                                    <div className="collection-banner p-right text-right">
                                        <div className="img-part">
                                            <img src={`https://animoxkart-products.s3.ap-south-1.amazonaws.com/banner/5.png`}
                                                 className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3">
                                            {/* <div>
                                                <h2>Combo</h2>
                                            </div> */}
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4">
                                <a href="shop/mega-combo">
                                    <div className="collection-banner p-right">
                                        <div className="img-part">
                                            <img src={`https://animoxkart-products.s3.ap-south-1.amazonaws.com/banner/6.png`}
                                                 className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3">
                                            {/* <div>
                                                <h2>Mega Combo</h2>
                                            </div> */}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Banner Section End*/}
                <SpecialProducts />
                {/*Product Section*/}
                <CollectionNew type={'wearable'} title="TOP COLLECTION" subtitle="Special Offer"/>
                <RegularCollection type={'wearable'} title="PRODUCTS" subtitle="New Offer" />
                <PremiumCollection type={'wearable'} title="PREMIUM PRODUCTS" subtitle="Special Offer"/>

                {/*Product Section End*/}

                {/*Parallax banner*/}
                <section className="p-0 pet-parallax">
                    <div className="full-banner parallax parallax-banner19  text-center p-center">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="banner-contain">
                                        <h4>choose what your pet love</h4>
                                        <h3>get upto 70% off</h3>
                                        <p>Don't miss out on our amazing sale! Right now, we're offering up to 70% off on a wide selection of pet accessories. Whether you're looking for a new collar and leash for your dog, or a cozy bed for your cat, we've got you covered. Our inventory includes everything from grooming tools and toys to carriers and bowls. All of our pet accessories are of the highest quality, so you can trust that your pet will be comfortable and safe. Don't wait too long, this sale won't last forever. Shop now and save big on all your pet needs! </p>
                                        <a href="shop/regular" className="btn btn-solid black-btn" tabIndex="0">shop now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pet-decor">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/dog.png`} alt="" className="img-fluid blur-up lazyload" />
                        </div>
                    </div>
                </section>
                {/*Parallax banner end*/}

                {/*Product Slider*/}
                <SaveAndExtraCollection type={'wearable'} title="SAVE AND EXTRA" />
                {/*Product Slider End*/}

                {/* Blog Section Section*/}
                {/* <div className="container ">
                    <div className="row">
                        <div className="col">
                            <div className="title1 title5">
                                <h4>Recent Story</h4>
                                <h2 className="title-inner1">from the blog</h2>
                                <hr role="tournament6" />
                            </div>
                        </div>
                    </div>
                </div>
                <section className="section-b-space p-t-0 ratio2_3">
                    <BlogSection />
                </section> */}
                {/* Blog Section End*/}
                 {/*Service Layout*/}
                 <div className="container">
                    <section className="service section-b-space border-section border-top-0">
                        <div className="row partition4">
                            <div className="col-lg-3 col-md-6 service-block1">
                                <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                                <h4>free shipping</h4>
                                <p>Enjoy fast, FREE delivery on over 1000 items at any pincode.</p>
                            </div>
                            <div className="col-lg-3 col-md-6 service-block1">
                                <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                                <h4>24 X 7 support</h4>
                                <p> We offer fast and quick solution on all types of Animoxkart products. Contact our Animoxkart customer care number.</p>
                            </div>
                            <div className="col-lg-3 col-md-6 service-block1">
                                <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                                <h4>festival offer</h4>
                                <p>Best Offers and coupons are available for all festivals. Check out first coupons, offers before you shop online.</p>
                            </div>
                            <div className="col-lg-3 col-md-6 service-block1">
                                <div dangerouslySetInnerHTML={{ __html: svgpayment }} />
                                <h4>online payment</h4>
                                <p>You can pay via any type of online payment services. 100+ modes - netbanking, all cards, UPI & Wallets.</p>
                            </div>
                        </div>
                    </section>
                </div>
                {/*Service Layout End*/}
                <ThemeSettings />
                <FooterTwo logoName={'logo/14.png'}/>
            </div>
        )
    }
}


export default Pets;