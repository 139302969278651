import React, { Component } from 'react';
import Slider from 'react-slick';

import { Slider6 } from "../../../services/script";

class ProductBlock extends Component {

    render() {
        return (
            <section className="section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <Slider {...Slider6} className="slide-6 no-arrow">
                                <div className="product-block">
                                    <div className="product-block-image-div" >
                                    <a href={`${process.env.PUBLIC_URL}/product/collar`}>
                                        <img src="assets/images/product-banner/collar.jpg" className="product-block-image" alt="Round Image" />
                                        </a>
                                    </div>
                                    <h5 className="product-block-label" >Collar</h5>
                                </div>
                                <div className="product-block">
                                    <div className="product-block-image-div" >
                                    <a href={`${process.env.PUBLIC_URL}/product/leash`}>
                                        <img src="assets/images/product-banner/leash.jpg" className="product-block-image" alt="Round Image" />
                                        </a>
                                    </div>
                                    <h5 className="product-block-label" >Leash</h5>
                                </div>
                                <div className="product-block">
                                    <div className="product-block-image-div" >
                                    <a href={`${process.env.PUBLIC_URL}/product/harness`}>
                                        <img src="assets/images/product-banner/harness.jpg" className="product-block-image" alt="Round Image" />
                                        </a>
                                    </div>
                                    <h5 className="product-block-label" >Harness</h5>
                                </div>
                                <div className="product-block">
                                    <div className="product-block-image-div" >
                                    <a href={`${process.env.PUBLIC_URL}/product/rope`}>
                                        <img src="assets/images/product-banner/rope.jpg" className="product-block-image" alt="Round Image" />
                                        </a>
                                    </div>
                                    <h5 className="product-block-label" >Rope</h5>
                                </div>
                                <div className="product-block">
                                    <div className="product-block-image-div" >
                                    <a href={`${process.env.PUBLIC_URL}/product/chain`}>
                                        <img src="assets/images/product-banner/chain.jpeg" className="product-block-image" alt="Round Image" />
                                        </a>
                                    </div>
                                    <h5 className="product-block-label" >Chain</h5>
                                </div>
                                <div className="product-block">
                                    <div className="product-block-image-div" >
                                    <a href={`${process.env.PUBLIC_URL}/product/body-belt`}>
                                        <img src="assets/images/product-banner/body_belt.jpg" className="product-block-image" alt="Round Image" />
                                        </a>
                                    </div>
                                    <h5 className="product-block-label" >Body Belt</h5>
                                </div>
                                {/* <div className="product-block">
                                    <div className="product-block-image-div" >
                                    <a href={`${process.env.PUBLIC_URL}/product/collar`}>
                                        <img src="" className="product-block-image" alt="Round Image" />
                                        </a>
                                    </div>
                                    <h5 className="product-block-label" >Collar</h5>
                                </div>
                                <div className="product-block">
                                    <div className="product-block-image-div" >
                                    <a href={`${process.env.PUBLIC_URL}/product/collar`}>
                                        <img src="" className="product-block-image" alt="Round Image" />
                                        </a>
                                    </div>
                                    <h5 className="product-block-label" >Collar</h5>
                                </div>
                                <div className="product-block">
                                    <div className="product-block-image-div" >
                                    <a href={`${process.env.PUBLIC_URL}/product/collar`}>
                                        <img src="" className="product-block-image" alt="Round Image" />
                                        </a>
                                    </div>
                                    <h5 className="product-block-label" >Collar</h5>
                                </div>
                                <div className="product-block">
                                    <div className="product-block-image-div" >
                                    <a href={`${process.env.PUBLIC_URL}/product/collar`}>
                                        <img src="" className="product-block-image" alt="Round Image" />
                                        </a>
                                    </div>
                                    <h5 className="product-block-label" >Collar</h5>
                                </div>
                                <div className="product-block">
                                    <div className="product-block-image-div" >
                                    <a href={`${process.env.PUBLIC_URL}/product/collar`}>
                                        <img src="" className="product-block-image" alt="Round Image" />
                                        </a>
                                    </div>
                                    <h5 className="product-block-label" >Collar</h5>
                                </div> */}
                                
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ProductBlock;