import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { getImage } from './../common/utils';
import {Helmet} from 'react-helmet';
import './blog.css';
import axios from 'axios';
import MultipleCollection from './../../components/layouts/pets/multipleCollection';
class BlogDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            blog:{
                "id": "",
                "blog_id": "",
                "title": "",
                "author_image": "",
                "blog_image": "",
                "author_name": "",
                "date": "",
                "short_description": "",
                "content": "",
                "tags": [],
                "product_tags": [],
                "video_url": "",
                "extend_content": "",
                "likes": "",
                "comments": [],
            },
        }
        
    }
    componentDidMount() {
        // Check if the component is in edit mode
        const { match } = this.props;
        const { params } = match;
        const { id } = params;
        this.getBlogById(id);


    }
    StripHtmlTags(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    getBlogById = async (id) => {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/getBlogById?id='+id, {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (response.data != null) {

        let blog =response.data[0];
            this.setState({
                blog: blog,
            })
        }

    }
    trimParagraph(paragraph) {
        // Maximum length for the meta description
        const maxLength = 160;
        const minLength = 150;
    
        // Remove leading and trailing spaces
        paragraph = paragraph.trim();
    
        // If the paragraph is shorter than the minimum length, return it as is
        if (paragraph.length <= minLength) {
            return paragraph;
        }
    
        // Find the position of the last period within the maxLength
        const cutOff = Math.min(paragraph.length, maxLength);
        const lastPeriodIndex = paragraph.lastIndexOf('.', cutOff);
    
        // If no period is found, use the maxLength
        const endIndex = lastPeriodIndex !== -1 ? lastPeriodIndex + 1 : cutOff;
    
        // Trim the paragraph to fit the desired length and ensure it ends with a full stop
        const trimmedParagraph = paragraph.substring(0, endIndex).trim();
    
        // Ensure the length is within the range
        return trimmedParagraph.length >= minLength ? trimmedParagraph : trimmedParagraph.substring(0, maxLength).trim();
    }
    
    render() {

        const blog = this.state.blog;
        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>Animoxkart | {blog.title}</title>
                    <meta name="keywords" content={blog.tags.join(", ")}/>
                    <meta name="description" content={this.trimParagraph(this.StripHtmlTags(blog.short_description))} />
                </Helmet>
                {/*SEO Support End */}
                <Breadcrumb title={'Blog'} />   

                <article className="blog-detail-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 blog-detail">
                                <h3>{blog.title}</h3>
                                <img src={`${blog.blog_image}`} className="img-fluid" alt={blog.title} />
                                <div className="description" dangerouslySetInnerHTML={{ __html: blog.short_description }} />
                                <br />
                                <ul className="post-social">
                                    <li>{this.state.blog.date}</li>
                                    <li>Posted By : {blog.author_name}</li>
                                    <li><i className="fa fa-heart"></i> {blog.likes} Likes</li>
                                    <li><i className="fa fa-comments"></i> {blog.comments.length} Comments</li>
                                </ul>
                                <br />
                                <section>
                                <div className="blog-content" dangerouslySetInnerHTML={{ __html: blog.content }} />
                                </section>
                               <section>
                               
                               <MultipleCollection type={'wearable'} title="" tags={blog.product_tags} />
                               </section>
                               <section>
                                <div className="blog-content" dangerouslySetInnerHTML={{ __html: blog.extend_content }} />
                                </section>
                            </div>
                        </div>
                        <div className="row section-b-space">
                            <div className="col-sm-12">
                                <ul className="comment-section">
                                {blog.comments.map((comment, index) =>  
                                    <li key={index}>
                                        <div className="media">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/avtar.jpg`} alt="Generic placeholder image" />
                                            <div className="media-body">
                                                <h6>{comment.name} <span>{comment.date}</span></h6>
                                                <p>{comment.comment}</p>
                                            </div>
                                        </div>
                                    </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="row blog-contact">
                            <div className="col-sm-12">
                                <h2>Leave Your Comment</h2>
                                <form className="theme-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <label htmlFor="name">Name</label>
                                            <input type="text" className="form-control" id="name"
                                                placeholder="Enter Your name" required="" />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" className="form-control" id="email" placeholder="Email"
                                                required="" />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="exampleFormControlTextarea1">Comment</label>
                                            <textarea className="form-control" placeholder="Write Your Comment"
                                                id="exampleFormControlTextarea1" rows="6"></textarea>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-solid" type="submit">Post Comment</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default BlogDetails