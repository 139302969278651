/**
 * Mocking client-server processing
 */

import axios from 'axios';
// const  fs = require('browserify-fs');
import _products from './data copy.json'
const TIMEOUT = 100
// const API_URL = 'http://localhost:8080/animoxkart/api/internal/getProducts';

// const getProductsFromAPI = () => {
//     return fetch(API_URL)
//       .then(response => {
//         if (!response.ok) {
//           throw new Error(`Network response was not ok: ${response.status}`);
//         }
//         return response.json();
//       })
//       .catch(error => {
//         console.error('Error fetching products:', error);
//       });
//   };

export default {
    getProducts: (cb, timeout) => setTimeout(() => cb(_products), timeout || TIMEOUT),
    // getProducts: (cb, timeout) => {
    //     getProductsFromAPI().then(products => {
    //         console.log(products)
    //       setTimeout(() => cb(products), timeout || TIMEOUT);
    //     });
    //   },
    buyProducts: (payload, cb, timeout) => setTimeout(() => cb(), timeout || TIMEOUT)
}
