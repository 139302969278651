import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";

class ReturnAndRefunds extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        return (
            <div>

                <Breadcrumb title={'Return And Refunds'} />


                {/*Return and Refunds section*/}
                <section className=" ">
                    <div className="container">
                        <div class="">
                            <h3 style={{ textAlign: "center" }}>
                                <strong>RETURN AND REFUND POLICY</strong>
                            </h3>
                            <div class="returnAndRefunds">
                            
Refund Return Policy

<p>Animoxkart sole aim is to make pets happy and ease paw-renting. We want to help pets and their parents live harmoniously and lead happy, healthy lives, and thus we aspire to deliver unique products sourced from the best manufacturers in the country and abroad.</p>

<p>Our policies are made keeping in mind the betterment of the relationships with our customers. These policies are developed to assure you that, subject to the following conditions, if any product purchased from Animoxkart is flawed or not suitable for you/your pet, we'll happily return or replace the merchandise or provide you with a Animoxkart Wallet Credit or direct payment for your return.</p>

<p>Within 7 days from the time of delivery, if you decide you no longer want a product you ordered, you can return it to us for an exchange or a store credit as long as the item and packaging received by Animoxkart are undamaged and in a resalable condition.</p>

<p>We don't provide any refunds for returns/exchanges. Except in few Cases</p>

<h4>Under what conditions can I return/ replace my product?</h4>
<p>Wrong product delivered</p>
<p>Expired product delivered</p>
<p>Damaged product/defective product delivered or damaged packaging </p>
<p>Please make sure to intimate us of the above-mentioned conditions with pictures within 48 hours from the date of delivery.</p>

<h4>Under what conditions return/replacement requests will not be accepted?</h4>
<p>In the event that the item is non-returnable, we would definitely hear your issue and try to solve it. But the sole decision whether to accept the return or not lies with Animoxkart. If we decide to </p>

<p>If such is the case, Animoxkart may impose a return charge, which would be equal to the shipping cost required to arrange the return. Before approving the return or exchange, this would be explained to you at the time of processing the return request. </p>

<h6>The returns or exchanges will not be accepted in the following circumstances:</h6>

<p>Used/ altered products.</p>
<p>Original packaging/labels missing.</p>
<p>The return/ replacement request is generated after 7 days from the date of delivery.</p>
<p>Not notifying & not sharing the images/vidoes of the defective products within 48 hours from the time of delivery</p>
<p>In case the product for exchange is unavailable, store credit is going to be offered.</p>

<p>NOTE : Dog shoes and clothes are non-refundable and non-exchangeable. For any queries, you can contact our customer support team and we will find the best solution to your problem.</p>

<h4>RETURN ELIGIBILITY </h4>

<p>The following items ARE NOT eligible for returns or exchanges unless the item has been received by the customer in a damaged or faulty condition:</p>

<p>Edible and perishable items like food, treats, supplements.</p>
<p>Any personalised product, personalised engraved identification tags.</p>
<p>Bedding, mats, beds, sofa, blankets, </p>
<p>Cat/Dog litter, trays, toys, & accessories</p>
<p>Cat furniture and scratchers</p>
<p>Grooming Products</p>
<p>Leashes & Harness</p>

<p>Please note the list above isn't comprehensive. There could also be other individual products like food, toys, grooming, collars, leashes, clothes, treats, supplements, and bowls & feeders that are non-returnable unless damaged. Product pages describe better whether a product is returnable or not.</p>

<h4>When can I be denied store credit/exchange?</h4>
<p>Please be aware that there will be no store credit given to you or an exchange of the product if we get an item without the original packing or if it is received broken, unclean, or worn (unless defective). Alternatively, you'll have the choice of getting the item shipped back to you at your own cost, otherwise, it will be donated.</p>

<p>This will be decided after the goods are delivered to the Animoxkart warehouse for inspection.</p>

<h4>Cancellation of Orders</h4>
<p>If you have placed an order and decide to cancel it before the shipment is sent, please contact our customer support team or drop us a mail. Please be aware that we will not be able to cancel an order after it has been shipped. Alternatively, you will have the option of accepting the delivery or rejecting it if you want.</p>

<h4>In Case Of Prepaid Order </h4>
<p>If you reject the delivery of a pre-paid order, a refund will be made to the original method of payment after it has been returned and investigated by us. </p>

<h4>In Case Of Cash On Delivery (COD)</h4>
<p>If you reject the delivery of a COD order, no refunds/store credits will be issued.</p>

 

<h4>How will I receive the refund for my cancelled or returned product?</h4>
<h6>Prepaid Order</h6>

<p>In case of prepaid orders, money will be returned to the bank account/ credit/debit card or where the payment was made within 8-10 business working days.</p>

<h6>Cash On Delivery (COD) Order</h6>
<p>For Cash on Delivery orders, customers will be required to provide bank details where they would like to receive the refund.</p>

<p>Alternatively, we will also provide you with an option to process your refund in your Animoxkart Wallet. </p>

<p>NOTE: You will not get a refund of the shipping/delivery charges. Only the product amount will be refunded. </p>

{/* Animoxkart WALLET CREDIT 
- How will I receive wallet credits?
Wallet credits are provided in the form of a coupon code. The coupon code (store credit) won't work with other discount codes or bundles/combo deals applied and remain valid for a month only.

Please note, when using your coupon code, please make sure that it's utilised in one transaction as any leftover amount wouldn't carry over.

- When will I receive this? 
After you've placed your return request, your exchange/wallet credit will only be issued once we've received the product in our warehouse. 

Receiving Wallet Credit

If you opt for receiving the refund in Animoxkart wallet, it will take 1-2 days maximum. */}

<h4>Refunding In Bank</h4>

<p>This will take anywhere from 8-14 business days.</p>

<p>- Amount of wallet credit.</p>
<p>If you've used a coupon code in your initial purchase, then please note that the amount of the discount won't be returned by way of wallet credit. The only amount provided by the way of wallet credit would be the amount paid by you after the discounted coupon is applied. The delivery fees paid by you within the initial purchase aren't eligible for any store credit. The shop credit is strictly limited to the worth of the product. </p>

<p>Whilst your first exchange is free, subsequent exchanges on an equivalent order will attract a delivery fee of flat Rs 100 and would be offset against your store credit.</p>

<h4>How can I place a return request? </h4>
<p>Please log your return request with us through our orders page with product images. Our customer support team will coordinate with you from thereafter with processing your request.</p>
<p>Once the item has been received at our warehouse, we will process the return/exchange for you. Please note any delivery fees incurred by you in returning the products are at your own cost and non-refundable by Animoxkart.</p>
<p>If for a few reasons we are unable to process the item pick-up from your location, it'll be your responsibility to ship the item to us at the subsequent address:</p>

<p>Animoxkart,</p>
<p>Anand Nagar, Kamre, Jhiri, Ranchi – 835222 Jharkhand, India</p>

<p>Email ID: Support@Animoxkart.in </p>
<p>Whatsapp : +91 - 8590501874</p>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default ReturnAndRefunds