import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom'
import TrackStatus from "./trackStatus";
import Breadcrumb from "../common/breadcrumb";
import SimpleReactValidator from 'simple-react-validator';
import Modal from 'react-responsive-modal';
import {getImage} from './../common/utils'
class Orders extends Component {

    constructor(props) {
        super(props)
        if (Cookies.get('id') === undefined) {
            Cookies.set('redirect', true)
            this.props.history.push('/login')
        }
        this.state = {
            orders: [],
            open: false,
            returnorder_placed_id: "",
            returncod: "",
            returnReason: "",
            returnDescription: "",
            returnImage: "",
            trackingData: [],

        }

        const data = {
            "contact_id": Cookies.get('id')
        }
        this.getOrderDetails(data)
        this.validator = new SimpleReactValidator();
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    calculateTimeDifference = (deliveryDateStr) => {

        if (deliveryDateStr != "null") {
            return 0;
        }
        const deliveryDate = new Date(deliveryDateStr);
        const currentDate = new Date();
        const timeDifference = currentDate - deliveryDate;
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24); // Convert milliseconds to days
        return parseInt(daysDifference, 10);
    };
    returnOrder = async (e, order_placed_id, cod) => {
        e.preventDefault();
        if (cod == true) {
            cod = 1;
        } else {
            cod = 0;
        }
        this.setState({
            returnorder_placed_id: order_placed_id,
            returncod: cod
        })
        this.onOpenModal();
    }
    returnOrderSubmit = async (e) => {
        e.preventDefault();
        // console.log(this.state)
        if (this.validator.allValid()) {
            const data = {
                "order_placed_id": this.state.returnorder_placed_id,
                "cod": this.state.returncod,
                "reason": this.state.returnReason,
                "description": this.state.returnDescription,
                "images": this.state.returnImage
            }
            // console.log(data)
            const response = await axios.post(process.env.REACT_APP_API_URL + '/returnOrder', data, {
                headers: {
                    "Content-Type": "application/json",
                    'Access-Control-Allow-Origin': '*',
                }
            });
            if (response.data != null) {
                if (response.data.status == true) {
                    window.location.reload();
                }
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    cancelOrder = async (e, order_placed_id, cod) => {
        e.preventDefault();
        if (cod == true) {
            cod = 1;
        } else {
            cod = 0;
        }
        const data = {
            "order_placed_id": order_placed_id,
            "cod": cod
        }
        const response = await axios.post(process.env.REACT_APP_API_URL + '/cancelOrder', data, {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (response.data != null) {
            if (response.data.status == true) {
                window.location.reload();

            } else {
                alert(response.data.message);
            }
        }
    }
    getOrderDetails = async (data) => {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/getOrdersById', data, {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (response.data != null) {
            this.setState({
                orders: response.data,
            })
            // console.log(response.data)
            document.getElementById("addressListSpinner").style.display = "none";
        }
    }
    trackOrderStatus = async (e, shipment_id, cod) => {
        e.preventDefault();
        let id = Cookies.get('id')
        const data = {
            "contact_id": id,
            "shipment_id": shipment_id,
            "cod": cod
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/getTrackOrderStatus', data, {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        });
        // console.log(response)
        if (response.data.status != false) {
            let trackData = response.data.track;
            const filteredData = [];

            if (data.length === 0) {
                return filteredData;
            }
            filteredData.push(trackData[0]);
            for (let i = 1; i < trackData.length; i++) {
                const currentEntry = trackData[i];
                const previousEntry = trackData[i - 1];
                if (currentEntry.status !== previousEntry.status) {
                    filteredData.push(currentEntry);
                }
            }

            this.setState({
                trackingData: filteredData,
            });
        }
    }
    showOrderDetails = (e, id) => {
        e.preventDefault();
        var x = document.getElementById("show" + id);
        if (x.className === "hideTable") {
            x.className = "showTable";
        } else {
            x.className = "hideTable";
        }
    }

    render() {
        return (
            <div>
                <Breadcrumb title={'Orders'} />


                {/*Regsiter section*/}
                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div id="addressListSpinner" style={{ textAlign: 'center' }}>
                                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                </div>
                                <div className='row'>
                                    {this.state.orders.length > 0 ?
                                        this.state.orders.map((order, index) => {
                                            return (
                                                <div className="col-lg-12 col-sm-12 mt-1" key={index}>
                                                    <div className="card" style={{ border: "1px solid black" }}>
                                                        <div className="m-1">
                                                            <p>Order Id: {order.order_placed_id}</p>
                                                            <p>Ordered on: {order.created_at}</p>
                                                            <p>{order.cod == true ? "Cash On Delivery  " : "Online Payment"}</p>
                                                        </div>
                                                        <div className="">
                                                            <section className="cart-section section-b-space" style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <table className="table cart-table table-responsive-xs">
                                                                                <thead>
                                                                                    <tr className="table-head">
                                                                                        <th scope="col">image</th>
                                                                                        <th scope="col">product name</th>
                                                                                        <th scope="col">price</th>
                                                                                        <th scope="col"></th>
                                                                                        <th scope="col">quantity</th>
                                                                                        <th scope="col">total</th>

                                                                                    </tr>
                                                                                </thead>
                                                                                {order.orderItems.map((item, index) => {
                                                                                    return (
                                                                                        <tbody key={index}>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <Link to={`${process.env.PUBLIC_URL}/view/product/${item.id}/${item.name}`}>
                                                                                                        <img src={`${item.newVariants ? getImage(item.newVariants.filter((variant) => variant.productCode == item.productCode)[0].images) : getImage(item.images)}`} alt={item.displayName} />
                                                                                                    </Link>
                                                                                                </td>
                                                                                                <td><Link to={`${process.env.PUBLIC_URL}/view/product/${item.id}/${item.name}`}>{item.displayName}</Link>
                                                                                                    <div className="mobile-cart-content row">
                                                                                                        <div className="col-xs-2">
                                                                                                            <h2 className='mr-1'>{item.quantity}</h2>
                                                                                                        </div>
                                                                                                        <div className="col-xs-1" style={{ "marginTop": "auto", "marginBottom": "auto" }}>
                                                                                                            <h2 >
                                                                                                                <a href={null} className="icon" >
                                                                                                                    <i className="fa fa-times"></i>
                                                                                                                </a>
                                                                                                            </h2>
                                                                                                        </div>
                                                                                                        <div className="col-xs-3">
                                                                                                            <h2 className="td-color">{item.price}</h2>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </td>
                                                                                                <td><h2>{item.price}</h2></td>
                                                                                                <td>
                                                                                                    <div className="col-xs-1" style={{ "marginTop": "auto", "marginBottom": "auto" }}>
                                                                                                        <h2 >
                                                                                                            <a href={null} className="icon" >
                                                                                                                <i className="fa fa-times"></i>
                                                                                                            </a>
                                                                                                        </h2>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div className="qty-box">
                                                                                                        <div className="input-group">
                                                                                                            <input type="text" name="quantity" value={item.quantity} readOnly={true} className="form-control input-number" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>

                                                                                                <td><h2 className="td-color">{(item.quantity * item.price)}</h2></td>

                                                                                            </tr>
                                                                                        </tbody>)
                                                                                })}
                                                                            </table>

                                                                            <table className="table cart-table table-responsive-md">
                                                                                <tfoot>
                                                                                    <tr>
                                                                                        <td>Subtotal :</td>
                                                                                        <td><h4>&#8377;{parseFloat(order.subtotal).toFixed(2)}</h4></td>
                                                                                    </tr>
                                                                                    {(order.subtotal - order.amount) > 0 ? (
                                                                                        <tr>
                                                                                            <td>Coupon :</td>
                                                                                            <td>
                                                                                                <h4 style={{ "color": "green" }}>
                                                                                                    -{parseFloat(order.subtotal - order.amount).toFixed(2)}
                                                                                                </h4>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ) : null}
                                                                                    {(order.totalPrice - order.subtotal) > 0 ? (
                                                                                        <tr>
                                                                                            <td>Total Saved:</td>
                                                                                            <td>
                                                                                                <h4 style={{ color: "green" }}>
                                                                                                    -{parseFloat(order.totalPrice - order.amount).toFixed(2)}
                                                                                                </h4>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ) : null}
                                                                                    <tr>
                                                                                        <td>Final price :</td>
                                                                                        <td><h4>&#8377;<b>{order.amount}</b></h4></td>
                                                                                    </tr>
                                                                                </tfoot>
                                                                            </table>

                                                                        </div>
                                                                    </div>
                                                                    <div className="row cart-buttons">
                                                                        {
                                                                            order.cancelled == 1 ?
                                                                                <>
                                                                                    <div className="col-8" >
                                                                                        <div id={order.order_placed_id + "cancel"}>
                                                                                            <div className="status-text">
                                                                                                <h6>Order Cancelled <i className="fa fa-check-cross"></i> <span>{order.cancel_details.cancelled_on ? " - " + order.cancel_details.cancelled_on : ""}</span> </h6>

                                                                                            </div>
                                                                                            {(order.cod == false) ?
                                                                                                <div className="status-text">
                                                                                                    {(order.refund == "0") ?
                                                                                                        <h6>Refund status : Pending</h6>
                                                                                                        : <h6>Refund status : {order.refund_details.refund_status ? order.refund_details.refund_status : "Initiated"}</h6>}
                                                                                                </div>
                                                                                                : ""}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <h4>Cancelled</h4>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                (order.shipped == 0) ?
                                                                                    <><div className="col-8"><h4>  <i className="fa fa-gift"></i> Ready To Ship</h4>
                                                                                    </div><div className="col-4">
                                                                                            <button className="btn btn-solid" onClick={(e) => this.cancelOrder(e, order.order_placed_id, order.cod)}>Cancel</button>
                                                                                        </div></>
                                                                                    :
                                                                                    (order.delivered == 1) ?
                                                                                        (order.returned != 0) ?
                                                                                            <>
                                                                                                <div className="col-8">

                                                                                                    <h6>Returned Status: <span>{order.return.return_status ? order.return.return_status : "Pending"}</span></h6>
                                                                                                    {order.return.confirmed_on ?
                                                                                                        <div className="status-text">
                                                                                                            <h6>Return Confirmed <i className="fa fa-check-circle"></i> <span>{order.return.confirmed_on ? " - " + order.return.confirmed_on : ""}</span> </h6>
                                                                                                        </div> : ""}
                                                                                                    {order.return.shipped_on ?
                                                                                                        <div className="status-text">
                                                                                                            <h6>Return Shipped <i className="fa fa-truck"></i><span>{order.return.shipped_on ? " - " + order.return.shipped_on : ""}</span></h6>
                                                                                                        </div> : ""}
                                                                                                    {order.return.deliver_on ?
                                                                                                        <div className="status-text">
                                                                                                            <h6>Return Received <i className="fa fa-check-square"></i><span>{order.return.deliver_on ? " - " + order.return.deliver_on : ""}</span></h6>
                                                                                                        </div> : ""}
                                                                                                    {order.return.deliver_on ? (order.refund == "0") ?
                                                                                                        <h6>Refund status : Pending</h6>
                                                                                                        : <h6>Refund status : {order.refund_details.refund_status ? order.refund_details.refund_status : "Initiated"}</h6> : null
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="col-4">
                                                                                                    <h4>Returned</h4>
                                                                                                </div>
                                                                                            </> :
                                                                                            <>
                                                                                                <div className="col-8">
                                                                                                    <h4>Delivered</h4>
                                                                                                </div>
                                                                                                <div className="col-4">
                                                                                                    {(this.calculateTimeDifference(order.delivered_date) <= 5) ?
                                                                                                        <button className="btn btn-solid" onClick={(e) => this.returnOrder(e, order.order_placed_id, order.cod)}>Return</button>
                                                                                                        : ""
                                                                                                    }

                                                                                                </div>
                                                                                            </>
                                                                                        :
                                                                                        <>
                                                                                            <div className="col-8">
                                                                                                <h4>Tracking Status</h4>
                                                                                                <TrackStatus data={this.state.trackingData} />
                                                                                            </div>
                                                                                            <div className="col-4">
                                                                                                <button className="btn btn-solid" onClick={(e) => this.trackOrderStatus(e, order.shipment_id, order.cod)}>Track</button>

                                                                                            </div>
                                                                                        </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                        }) :
                                        <div className="col-sm-12 empty-cart-cls text-center">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" alt="" />
                                            <h3><strong>No Orders Found</strong></h3>
                                            <h4>Explore more shortlist some items.</h4>
                                        </div>
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                    <Modal open={this.state.open} onClose={this.onCloseModal} center>
                        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div className="modal-content quick-view-modal">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className='col-12'>
                                            <h3>Fill Details</h3>
                                        </div>
                                        <div className="col-lg-6  col-xs-12">
                                            <h5>Order ID : {this.state.returnorder_placed_id} </h5>
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlSelect1">Reason for return</label><span style={{ color: "red" }}>*</span>
                                                <select className="form-control" name="returnReason" id="exampleFormControlSelect1" onChange={(e) => this.setState({ returnReason: e.target.value })}>
                                                    <option value="">Select Reason</option>
                                                    <option value="Product Damaged">Product Damaged</option>
                                                    <option value="Product not as described">Product not as described</option>
                                                    <option value="Product not as expected">Product not as expected</option>
                                                    <option value="Product not as shown">Product not as shown</option>
                                                    <option value="Product not as ordered">Product not as ordered</option>
                                                    <option value="Product not as advertised">Product not as advertised</option>
                                                </select>
                                                {this.validator.message('returnReason', this.state.returnReason, 'required')}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlTextarea1">Description</label><span style={{ color: "red" }}>*</span>
                                                <textarea className="form-control" name="returnDescription" id="exampleFormControlTextarea1" rows="3" onChange={(e) => this.setState({ returnDescription: e.target.value })}></textarea>
                                                {this.validator.message('returnDescription', this.state.returnDescription, 'required')}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xs-12">
                                            {/* <div className="form-group">
                                                <label htmlFor="exampleFormControlSelect1">Upload Product Image</label><span style={{ color: "red" }}>*</span>
                                                <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={(e) => this.setState({ returnImage: e.target.files[0] })} />
                                            </div>
                                            <div className="quick-view-img">
                                            <img src={this.state.returnDetails.returnImage} alt="" className="img-fluid" />
                                            </div> */}
                                            <div>
                                                <button className="btn btn-solid" onClick={(e) => this.returnOrderSubmit(e)}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </section>

            </div>
        )
    }
}

export default Orders