import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";

class TermsOfUse extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        return (
            <div>

                <Breadcrumb title={'Terms Of Use'} />


                {/*Terms Of Use section*/}
                <section className=" ">
                    <div className="container">
                        <div class="">
                            <h3 style={{ textAlign: "center" }}>
                                <strong>TERMS OF USE</strong>
                            </h3>
                            <div class="termsandconditions">
                                <br />
                                <br />
                                <p>This Website, "<strong>www.animoxkart.in</strong>"&nbsp; is owned and operated by <strong>Animoxkart</strong>&nbsp;.</p>
                                <p>Before utilizing any services on Animoxkart, we strongly recommend that you carefully read and agree to these Terms of Use, as well as our Privacy Policy.</p>
                                <p>By accessing or using Animoxkart in any manner, which includes but is not limited to visiting, browsing, or contributing content or other materials to Animoxkart, you explicitly agree to be bound by these Terms of Use.</p>
                                <br />
                                <h4><strong> ACCEPTANCE OF TERMS AND CONDITIONS</strong></h4>
                                <p>These Terms of Use&nbsp;(hereinafter referred to as "<strong>Terms and Conditions</strong>" or "<strong>T&amp;C</strong>" or "<strong>Terms</strong>" or "<strong>Agreement</strong>"), along with any other Policy, Statement, or Information that may be placed on this website (hereinafter referred to as&nbsp;“Animoxkart” or “Website” or “We” or “Us”), as modified or amended from time to time, are a binding contract between the Company and You (hereinafter referred to as "<strong>You</strong>" or "<strong>End User</strong>" or "<strong>Your</strong>" or "<strong>Buyer</strong>" or "<strong>Customer</strong>")</p>
                                <p>By visiting, using, or shopping at the site or any future site operated by the company, you accept these Terms and Conditions. Furthermore, when you use any current or future services of the company or visit or purchase from any business affiliated with the company or third-party vendors, whether or not included in the site, you will also be subject to the guidelines and conditions applicable to such service or merchant. In the case of inconsistency between these conditions and such guidelines and conditions, the latter will prevail.</p>
                                <p>By subscribing to or using any of our products or services, you affirm that you have read, understood, and are bound by the Terms and Conditions, regardless of how you subscribe to or use our products or services. If you do not wish to be bound by these terms, you must refrain from subscribing to or using our services.</p>
                                <br />
                                <h4><strong> ELIGIBILITY</strong></h4>
                                <p>Use of this website is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to use this Website.</p>
                                <p>The Service is not available to minors under the age of 18 or to any users suspended or removed from the system by Animoxkart.in for any reason. If you are a minor i.e. under the age of 18 years, you shall not purchase any items on the Website. As a minor if you wish to purchase an item on the Website, such a purchase may be made by your legal guardian or parents.</p>
                                <p>Users may not have more than one account. Maintaining more than one account by a user shall amount to fraudulent act on part of the user and attract actions against such users. Additionally, users are prohibited from selling, trading, or otherwise transferring your Animoxkart.in account to another party. If you do not qualify, you may not use the Service or the Site.</p>
                                <p>The Company owns no responsibility in any manner over any dispute arising out of transactions by any third party using your account/e-mail provided by you to the Company or payments made by your credit card by any third party.</p>
                                <p>In consideration of your use of the Site, you represent that you are of legal age to form a binding contract and are not a person barred from receiving services under the laws as applicable in India. You also agree to provide true, accurate, current and complete information about yourself as prompted by the Site's registration form. If you provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or Animoxkart.in has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Animoxkart.in has the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof). If you use the Site, you are responsible for maintaining the confidentiality of your account and password including cases when it is being used by any of your family members, friends or relatives, whether a minor or an adult. You further agree to accept responsibility for all transactions made from your account and any dispute arising out of any misuse of your account, whether by any family member, friend, relative, any third party or otherwise shall not be entertained by the Company. Because of this, we strongly recommend that you exit from your account at the end of each session. You agree to notify Animoxkart.in immediately of any unauthorized use of your account or any other breach of security. Animoxkart.in reserves the right to refuse service, terminate accounts, or remove or edit content in its sole discretion.</p>
                                <p>If you are a business entity, you represent that you are duly authorised by the business entity to accept these terms and conditions and you have the authority to bind that business entity to these terms and conditions.</p>
                                <br />
                                <h4><strong> MODIFICATION</strong></h4>
                                <p>Animoxkart reserves the right to change, modify, adjust, vary, amend or alter all or any of its Terms of Use at any time and at its sole discretion. All such changes, modifications, adjustments, amendments and alterations shall be duly notified by Animoxkart however, it is the responsibility of the User to keep himself/herself updated regarding such modifications. Animoxkart shall in no case be held liable in respect of such modifications. The User agrees to abide by all applicable guidelines, policies, rules, terms and conditions for availing the Services on Animoxkart, which may change from time to time.</p>
                                <br />
                                <h4><strong>PRODUCT INFORMATION, PRICING AND PROMOTIONAL DISCOUNTS</strong></h4>
                                <p>While Animoxkart.in strives to provide accurate product and pricing information, typographical errors may occur. In the event that a product is listed at an incorrect price or with incorrect information due to an error in pricing or product information, Animoxkart.in may, at its discretion, either contact you for instructions or cancel your order and notify you of such cancellation. Animoxkart.in will have the right to modify the price of the product and contact you for further instructions using the e-mail address or telephone number provided by you during the time of registration or cancel the order and notify you of such cancellation.</p>
                                <p>The price of all products, delivery charges and any other applicable charges are displayed in Indian Rupees (INR). All prices are current at time of display, but these prices are subject to change without notice.<br /> <br /> Animoxkart has made every effort to display as accurately as possible the colours of our products that appear on the website. Further, Animoxkart has ensured that the measurements, information and description for products furnished on the site are best calculated and stated to accuracy and true to its dimensions. However, due to the inherent characteristics of certain materials, actual measurements of individual items might vary slightly.</p>
                                <p>We offer you promotional discount codes that are applicable on the purchases made on this website. These discount codes can be applicable on all or certain specified products. Please note that use of only one discount code is permissible per order. You cannot use a discount code if an order is already placed.</p>
                                <p>As a condition of purchase, the Site requires your permission to send you administrative and promotional emails/calls. We will send you information regarding your account activity and purchases, as well as updates about our products and promotional offers. We shall have no responsibility in any manner whatsoever regarding any promotional emails/calls/SMS/MMS sent to you. The offers made in those promotional emails/calls/SMS/MMS shall be subject to change at the sole discretion of the Company and the Company owes no responsibility to provide you any information regarding such change.</p>
                                <p>You are required to create an account in order to purchase any product from the Site. This is required so we can provide you with easy access to print your orders and view your past purchases.</p>
                                <p>The Site takes no responsibility for the services or products that are sold or supplied by third party vendors. The Company makes no warranty to their end users for the quality, safety, usability, or other aspect of a product or service that is supplied by a Merchant.</p>
                                <br />
                                <h4><strong> CONTRACT FOR SALE OF GOODS</strong></h4>
                                <p>The parties to the contract are Animoxkart ("the supplier") and you ("the purchaser"). The terms and conditions of this website constitute the terms and conditions of the contract. By placing an order you confirm that you have read the terms and conditions and accept them.</p>
                                <p>Payment must be made at the time the products are ordered (unless you opt for the Cash-On-Delivery option). When you place an order you will be automatically redirected to the secure Razorpay or PayPal website for you to complete payment. Animoxkart does not keep a record of your credit card details.</p>
                                <p>Animoxkart retains the right to accept or deny any offer made. This may be due to the availability of any product, or for any other reason that may affect our ability to supply the products ordered. No contract is formed until we communicate acceptance of your order.</p>
                                <p>The electronic contract shall have the same legal force and effect as a written contract signed by you.</p>
                                <br />
                                <h4><strong> CANCELLATION POLICY</strong></h4>
                                <p><strong>*</strong><strong>Cancellations by Animoxkart</strong></p>
                                <p>Animoxkart has a right to cancel or refuse the order for any reason. Some situations that may result in your order being cancelled include limitations on quantities available for purchase, inaccuracies or errors in product or pricing information, or problems identified by our credit and fraud avoidance department. We may also require additional verifications or information before accepting any order. We will contact you if all or any portion of your order is cancelled or if additional information is required to accept your order. If your order is cancelled after your credit card has been charged, the said amount will be reversed back in your Card Account.</p>
                                <p><strong>*</strong><strong>Cancellations by You</strong></p>
                                <p>In case of requests for order cancellations, Animoxkart reserves the right to accept or reject requests for order cancellations for any reason. For you to cancel an order, there is a time period of 24 hours from the time you place the order. As part of usual business practice, if we receive a cancellation notice and the order has not been processed / approved by us, we shall cancel the order and refund the entire amount with-in 15-20 working days.</p>
                                <p>We will not be able to cancel orders that have already been processed.</p>
                                <p>Animoxkart&nbsp;has the full right to decide whether an order has been processed or not. You agree not to dispute the decision made by Animoxkart and accept Animoxkart decision regarding the cancellation.</p>
                                <br />
                                <h4><strong> SHIPPING POLICY</strong></h4>
                                
                                <p>Orders placed on Animoxkart are typically processed within 1-2 business days, excluding weekends and public holidays. During peak seasons or promotions, processing times may be slightly longer.</p>
                                <p><b>Minimum Delivery Time: </b> The minimum delivery time for orders is 5 days from the date of order confirmation.</p>
                                <p><b>Maximum Delivery Time: </b>  The maximum delivery time is 28 days from the date of order confirmation. While we strive to deliver your orders as quickly as possible, certain factors such as location, customs clearance, and unforeseen circumstances may impact delivery times.</p>
                                <p>Animoxkart uses reputable courier services for all deliveries. The choice of courier may vary depending on the destination and nature of the products ordered.</p>
                                <p>Shipping rates are calculated based on the weight of the items and the destination. The rates will be displayed during the checkout process, allowing you to review and confirm before completing your purchase.</p>
                                <p>Once your order has been shipped, you will receive a confirmation email with a tracking number and a link to track your order or you can track status in orders. Please note that tracking information may take some time to become available.</p>
                                <p>While we make every effort to meet the specified delivery times, unforeseen circumstances such as weather conditions, customs inspections, or other external factors may cause delays. In such cases, we will do our best to keep you informed and minimize any inconvenience caused.</p>
                                <p>Once an order has been confirmed, modifications to the shipping address or other details may not be possible. Please ensure that your shipping information is accurate and up-to-date before completing your purchase.</p>
                                <br />
                                <h4><strong> PROHIBITIONS</strong></h4>
                                <p>Animoxkart grants you a limited license to access and make personal use of the website and services.</p>
                                <p>The following actions will be considered as misuse of the website, and are thus prohibited:</p>
                                <p>- You shall not distribute in any form, any information, or other material that violates, infringes the copyrights, patents, trademarks, trade secrets, logo or other proprietary rights of Animoxkart.</p>
                                <p>- You are not allowed to reproduce, modify, distribute, display any portion, publish any content or make any commercial use of any of the information provided in this website.</p>
                                <p>- You are not allowed to republish, archive or retain any content on the internet, intranet, extranet, database, archive or compilation. You are not allowed to use any content for commercial use.</p>
                                <p>- You are not allowed to use the website in any manner that is illegal or impairs the operation of the website or its availability or usage by others.</p>
                                <p>- You agree not to decompile, reverse engineer or disassemble any software or other products or processes accessible through the website, and not to insert any code or product or manipulate the content in any way that affects the user's experience.</p>
                                <p>- We hereby hold no liability to any sort of damage or harm caused to your software, data or computer device by downloading content from this website.</p>
                                <p>- You further agree not to use any data mining, bugs, viruses, worms, trap doors, web crawlers, robots, cancel bots, spiders, Trojan horses, other harmful code of properties or any data gathering or extraction method in connection with your use of the website.</p>
                                <p>- You are not allowed to make any use of the website for the benefit of another business.</p>
                                <p>- You are not allowed to post unsolicited promotional or advertising content.</p>
                                <br />
                                <h4><strong> INTELLECTUAL PROPERTY SOFTWARE AND CONTENT</strong></h4>
                                <p>Animoxkart owns all the content available on this website, including, the text, graphics and copyright works. Animoxkart is the exclusive owner of all rights in the compilation, design and layout of this website.</p>
                                <p>You may not copy any content from this website without the prior written consent from Animoxkart. Modification, misuse, translation or creation of derivative work on the basis of website content is highly prohibited.</p>
                                <p>Animoxkart.in grants you a limited license to access and make personal use of the Site and the Service. This license does not include any downloading or copying of account information for the benefit of another vendor or any other third party; caching, unauthorized hypertext links to the Site and the framing of any Content available through the Site uploading, posting, or transmitting any content that you do not have a right to make available (such as the intellectual property of another party); uploading, posting, or transmitting any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; any action that imposes or may impose (in Animoxkart.in sole discretion) an unreasonable or disproportionately large load on Animoxkart.in infrastructure; or any use of data mining, robots, or similar data gathering and extraction tools. You may not bypass any measures used by Animoxkart.in to prevent or restrict access to the Site. Any unauthorized use by you shall terminate the permission or license granted to you by Animoxkart.in.</p>
                                <br />
                                <h4><strong> TAXES</strong></h4>
                                <p>You shall be responsible for payment of all fees/costs/charges associated with the purchase of products from us and you agree to bear any and all applicable taxes including but not limited to VAT/CST, service tax, GST, duties and cesses etc.</p>
                                <h4><strong> ERRORS, INACCURACIES, AND OMISSIONS</strong></h4>
                                <p>There may be information on our site that contains typographical errors, inaccuracies, or omissions that may relate to product descriptions, pricing, promotions, offers, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions and to change or update information without prior notice (including after you have submitted your order).</p>
                                <p>In the event a product is listed at an incorrect price or with incorrect information due to a typographical error or error in pricing or product information, Animoxkart shall have the right to refuse or cancel any orders placed for the product listed at the incorrect price whether or not the order has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is cancelled, Animoxkart shall immediately issue credit in the amount of the charge.</p>
                                <br />
                                <h4><strong> LINKS TO OTHER SITES</strong></h4>
                                <p>This website may contain links to third party websites. Any outside links are provided only as a convenience. Your use of outside links is at your sole risk. Links from the website do not constitute Animoxkart endorsement of any third party, its website, or its goods or services. Animoxkart is not responsible for any outside sites, services or other materials linked to or from the website, and disclaims all liability for any injury you may experience by using such materials.</p>
                                <br />
                                <h4><strong> GUARANTEES AND WARRANTIES</strong></h4>
                                <p>To the extent permitted by law, Animoxkart excludes all warranties, representations and guarantees (whether express, implied or statutory), and we will not be liable for any damages, losses or expenses, or indirect losses or consequential damages of any kind, suffered or incurred by you in connection with your access to or use of this website or the content on or accessed through it.<br /> <br /> This disclaimer of liability also applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorised access to, alteration of, or use of record, whether for breach of contract, tortuous behaviour, negligence, or under any other cause of action.</p>
                                <br />
                                <h4><strong> PRIVACY</strong></h4>
                                <p>Please review our Privacy Policy, which also governs your visit to Animoxkart.com, to understand our practices. The personal information / data provided to us by you during the course of usage of Animoxkart.com will be treated as strictly confidential and in accordance with the Privacy Policy and applicable laws and regulations. If you object to your information being transferred or used, please do not use the website.</p>
                                <br />
                                <h4><strong> ACCOUNT INFORMATION</strong></h4>
                                <p>Animoxkart may assign you a password and account for identification, to enable you to access and use certain portions of this website. Each time you use a password or identification, you will be deemed to be authorized to access and use the website in a manner consistent with the terms and conditions of this agreement.</p>
                                <p>Animoxkart has no obligation to investigate the authorization or source of any such access or use of the website. You are solely responsible for protecting the security and confidentiality of the password and identification assigned and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password.<br /> You shall immediately notify Animoxkart of any unauthorized use of your password or identification or any other breach or threatened breach of this website's security.</p>
                                <p>You will be solely responsible for all access to and use of this site by anyone using this password and identification whether or not such access to and use of this site (including all obligations, communications and transmissions) is actually authorized by you.<br /> Animoxkart reserves the right to refuse service, terminate accounts, and/or cancel orders at its discretion, including, without limitation, if&nbsp; Animoxkart believes that the conduct by the customer violates applicable law or is harmful to website.</p>
                                <br />
                                <h4><strong>FRAUDS</strong></h4>
                                <p>Animoxkart reserves the right to recover the cost of goods, collection charges and other expenses that may occur from persons for using the Site fraudulently. Animoxkart reserves the right to initiate legal proceedings against such persons for fraudulent use of the Site and any other unlawful acts or omissions in breach of these terms and conditions.</p>
                                <br />
                                <h4><strong>REDEEM VOUCHERS AND PROMOTIONAL CODES</strong></h4>
                                <p>There are limited period vouchers and promotional codes which can be issued or cancelled by Animoxkart as part of any campaign or scheme. The terms for these campaigns will be decided by Animoxkart at the time of the campaign and these should be acceptable to you.</p>
                                <br />
                                <h4><strong> INDEMNIFICATION</strong></h4>
                                <p>You shall indemnify and hold harmless Animoxkart, its owner, licensee, affiliates, subsidiaries, group companies (if any) and their respective officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys' fees, made by any third party or penalty imposed due to or arising out of Your breach of this Terms of Use, privacy Policy and other Policies, or Your violation of any law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party.</p>
                                <br />
                                <h4><strong> GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION</strong></h4>
                                <p>Animoxkart controls and operates this website from its Registered Office in Jharkhand. These Terms of Use (and any further rules, polices, or guidelines incorporated by reference) shall be governed and construed in accordance with the laws of India.</p>
                                <p>Any dispute arising under or relating to the terms, contents, your use of the website, or products or services purchased using the website or with Animoxkart shall solely and to the exclusion of all other courts be subject to the jurisdiction of the appropriate Courts situated in Jharkhand, India alone. By using the website, you consent to the jurisdiction and venue of Jharkhand courts with respect to any such dispute.</p>
                                <br />
                                <h4><strong> GRIEVANCE REDRESSAL</strong></h4>
                                <p>Any complaint or concern with regard to the Services, access, usage, content, comment or breach of the Terms of Use shall be addressed to the designated Grievance Officer of Animoxkart. The complaint shall be registered through a phone call on the number provided below or by sending an email to the respective email ID as provided below. It shall be the endeavour of Animoxkart to satisfactorily resolve and address the grievances at the earliest.</p>
                                <p>Phone number: - 08590501874</p>
                                <p>Email - <a href="mailto:support@animoxkart.in">support@animoxkart.in</a></p>
                                <br />
                                <h4><strong> DISCLAIMER</strong></h4>
                                <p>You acknowledge and undertake that you are accessing the services on the site and transacting at your own risk and are using your best and prudent judgment before entering into any transactions through Animoxkart. If you are dissatisfied with the Site, any Contents, or any of these Terms and conditions, we would like to hear from you. However, your only legal remedy is to stop using the website. Animoxkart does not warrant your use of the Site.</p>
                                <br />

                                <h4><strong> TERMINATION</strong></h4>
                                <p>Animoxkart reserves the right to terminate the User’s access to Animoxkart without any cause or notice. This may result in the forfeiture and destruction of all information associated with the User. In such an event, the Terms of Use shall come to an end. However, the Indemnification, Disclaimer, Governing Law/Jurisdiction and Dispute Resolution and Privacy Policy shall survive such termination.</p>
                                <br />
                                <h4><strong> ENTIRE AGREEMENT</strong></h4>
                                <p>These Terms of Use constitutes the complete agreement and sets forth the entire understanding of you and the Animoxkart as to the subject matter of this Agreement. If any provision of this Agreement is found by any court of competent jurisdiction to be invalid or unenforceable, the invalidity of such provision shall not affect the other provisions of this Agreement, and all provisions not affected by such invalidity shall remain in full force and effect. The headings contained in this Agreement are for convenience of reference only and shall not affect the meaning and interpretation of this Agreement.</p>
                                <p>By accepting these Terms of Use, the User agrees to have fully read and understood all the terms and conditions set out hereinabove.</p>

                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default TermsOfUse